
@import 'assets/styles/variables';

.capture-section{
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.measurement-container {
  .btn-logout{
    position: absolute;
    z-index: 888;
    right: 15px;
    bottom: 15px;
    background-color: $results-item-color;
    height: 40px;
    width: 40px;
    border-radius: 6px;
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: auto;
  padding: 2rem 0 ;
  justify-content: center;
  .measurement-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0rem 2rem 2rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.2);
    align-items: center;
    width: 650px;
    min-height: auto;
    .start-block-wrapper{
      height: 170px;
    }
    .custom-button{
      height: 3rem;
      background: linear-gradient(to right, #14418C, #2270F1);
      border-radius: 0.625rem;
      width: 10rem;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;

      span{
        font-size: 1.125rem;
        line-height:  1.125rem !important;
        font-weight: 600;
        line-height: normal;
      }
    }
    .video-container.not-ready-class {
      filter: blur(10px);
    }
    .video-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 400px;

      .video-wrapper {
        width: 350px;
        height: 350px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        overflow: hidden;

        .video,
        .canvas {
          height: 350px;
          width: 350px;
          object-fit: cover !important;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          
          &.invert {
            transform: translate(-50%, -50%) scaleX(-1);
          }
    
          &.vertical {
            height: 100%;
            width: 100%;
          }
        }
        
        .canvas {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) scaleX(-1);
          z-index: 10;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 767.98px) { 
  .capture-section{
  .measurement-container {
    padding: 2rem 0 3.5rem;
    .measurement-wrapper {
      width: 90%;
      .start-block-wrapper{
        min-height:270px;
        height: auto;
      }
      .video-container {
        width: 320px;
        height: 320px;
        .video-wrapper {
          width: 300px;
          height: 300px;
          .video,
          .canvas {
            width: 300px;
            height: 300px;
          }
        }
      }
    }
     .btn-logout{
      bottom: 10px;
      left: auto;
      right: auto;
    }
  }
 }
}

@import 'assets/styles/variables';

.loading-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;
  .spinner-wrapper {
    position: relative;
    height: 6.6rem;
  }

  .message-wrapper {
    color: #333;
    font-size: 12px;
    font-weight: 600;
  }

}
@media (max-width: 767.98px) { 
  .loading-screen-container {
    .message-wrapper {
      font-size: 10px
    }
  }
}
@import 'assets/styles/variables';

.image-quality-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: -10px 0;
  gap:5px;

  .image-quality {
    width: 20px;
    height: auto;
    color: transparent;

    &.active {
      color:#D86E08;
    }
  }
}
@media (max-width: 767.98px) { 
  .image-quality-container {
    margin: 10px 0 -5px 0;
  }
}
@import 'assets/styles/variables';

.bad-conditions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98vh;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  z-index: 1;
  .message-wraper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.2);
  
  .title-error {
    color: $carnation;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;

    &:before {
      background-image: url('/assets/images/error-icon.svg');
      content: '';
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      background-position: 50%;
      background-repeat: no-repeat;
      margin-right: 0.4rem;
      background-size: cover;
    }
  }

  .message-container {
    margin-bottom: 2rem;
    
    .title {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.5rem;
      color:black;
      margin-bottom: 2rem;
    }
  
    .content {
      font-size: 1.25rem;
      color: black;
    }
  }
    .custom-button {
      height: 3rem;
      background: linear-gradient(to right, #14418C, #2270F1);
      border-radius: 0.625rem;
      width: 10rem;
      margin-top: 1rem;
      cursor: pointer;
      span {
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
      }
    }
  }

}
@media (max-width: 768.98px) { 
  .bad-conditions-container {
    .message-wraper {
      width: 90%;
      .content {
        font-size: 1rem !important;
      }
    }
  }
}
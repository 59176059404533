@import 'assets/styles/variables';

.notification-container {
  width: 100%;

  .notification {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 0.25rem;
  }

  .notification-type {
    color: $cream-can;
    margin: 0.345rem 0;

    .notification-type-text {
      font-size: 1rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      margin: 0;
      &:before {
        background-image: url('/assets/images/warning-icon.svg');
        content: '';
        width: 1rem;
        height: 1rem;
        display: inline-block;
        background-position: 50%;
        background-repeat: no-repeat;
        margin-right: 0.25rem;
      }
    }
  }
}
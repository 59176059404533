@import 'assets/styles/variables';

.results-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 98vh;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;
  .btn-logout{
    position: absolute;
    z-index: 888;
    right: 15px;
    bottom: 15px;
    background-color: $results-item-color;
    height: 40px;
    width: 40px;
    border-radius: 6px;
    cursor: pointer;
  }
  .content-border{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.2);
    z-index: 1;
    .msg-block {
      max-width: 536px;
      .title {
        font-weight: 700;
        font-size: 32px;
        color: #000;
        margin-top:0px;
        margin-bottom: 1rem;
        line-height: 1;
      }
      .description {
        font-weight: 400;
        font-size: 20px;
        color: #565656;
        margin-bottom: 1.5rem;
      }
    }

    .results {
      min-height: 9.875rem;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 90%;
      justify-content: center;

      .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        font-weight: $results-item-font-weight;
        font-size: $results-item-font-size;
        color: $results-item-color;
        border-bottom: 1px solid #E0E0E0;

        .title {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          color: #000;
          .icon {
            font-size: $font-size-nano;
            margin-right: 1.3rem;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #CFCFCF;
            width: 4rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name {
            color: #565656;
          }
        }

        .value {
          text-align: right;
          color: #000;

          .sign {
            margin-left: 0.25rem;
          }
        }
      }
      .item:last-child { border:unset }

      .notification {
        display: flex;
        flex-direction: row;
        color: $results-item-color;
        text-align: left;
        font-size: $results-item-font-size;

        img {
          margin-right: 0.5rem;
        }
      }

    }

    .input-email {
      width: 20rem;
      box-sizing: border-box;
      margin: 0.5rem;
      padding: 1.3rem 1.625rem;
      border-radius: 10px;
      border: none;
    }
    .custom-button {
      height: 3rem;
      background: linear-gradient(to right, #14418C, #2270F1);
      border-radius: 0.625rem;
      width: 14rem;
      margin-top: 1.2rem;

      span {  
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 768.98px) { 
  .results-container {
    .content-border{
      width: 90%;
      .results {
        width: 100%;
        .title {
        .icon {
          width: 2.5rem !important;
          height: 2.5rem !important;
        }
      }
      }
      .msg-block {
        .description{
          font-size: 16px;
        }
      }
    }
    .btn-logout{
      bottom: 10px;
      left: auto;
      right: auto;
    }
  }
}
@import 'assets/styles/variables';
.error-notification-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 98vh;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;
  z-index: 1;
  .btn-try{
    background: linear-gradient(to right, #14418C, #2270F1);
    width: 140px;
    height: 50px;
    border-radius: 8px;
    border: 0px;
    color: #fff;
    cursor: pointer;
    font-weight: 700 ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    .fm{
      font-weight: 700 !important;
    }
}
  .error-content{
    text-align: center;
    max-width: 600px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.2);
    .warning-container{
      min-height: auto !important;
      margin-bottom: 2rem;
    .warning-message{
      color: #000 !important;
      font-size: 1.4rem !important;
    }
  }
  }
}

@media (max-width: 768.98px) { 
  .error-notification-container {
    .error-content{
      max-width: 90%;
      .warning-container{
        .warning-message{
          font-size: 1rem !important;
        }
      }
    }
  }
}
.overlap_box{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
   background-image: linear-gradient(
  210deg,
  hsl(218deg 75% 31%) 13%,
  hsl(217deg 74% 34%) 38%,
  hsl(217deg 74% 36%) 45%,
  hsl(217deg 73% 38%) 48%,
  hsl(217deg 73% 40%) 49%,
  hsl(217deg 73% 41%) 49%,
  hsl(217deg 72% 43%) 48%,
  hsl(217deg 72% 45%) 48%,
  hsl(217deg 73% 46%) 47%,
  hsl(217deg 73% 48%) 47%,
  hsl(217deg 74% 49%) 48%,
  hsl(217deg 75% 50%) 50%,
  hsl(217deg 79% 52%) 54%,
  hsl(217deg 84% 53%) 63%,
  hsl(217deg 88% 54%) 99%
  );
  }
@import 'assets/styles/variables';
.ui-provider > svg{
 display: none;
}
.login_section{
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    position: relative;
    z-index: 1;
    .login_inner_section{
        background-color: #fff;
        padding: 2rem;
        border-radius: 1rem;
        width: 450px;
        box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.2);
    }
    .login_title{
        font-weight: 600;
        margin-top: 30px;
    }
    .login_detail{
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 32px;
    }
    .select_country_box{
        position: relative;
        display: flex;
        .select_countrey{
            width: 80px;
            .country_code_input{
                width: 80px;
                height: 50px;
                border: 1px solid #ccc;
                border-right: 0px;
                border-radius: 5px 0px 0px 5px;
                padding-left: 10px;
                outline: none;
            }
            .dropdown_list{
                position: absolute;
                z-index: 0;
                background: #fff;
                border-radius: 5px;
                top: 49px;
                padding-top: 10px;
                height: 200px;
                overflow-y: scroll;
                text-align: left;
                border: 1px solid #ccc;
                width: 100%;
                .countrey_name{
                    font-size: 14px;
                    padding: 10px;
                    &:hover{
                        background-color: rgb(218, 230, 249);
                        cursor: pointer;
                    }
                }
            }
        }
        .mobile_input{
            border: 1px solid #ccc;
            border-radius: 0px 5px 5px 0px;
            padding-left: 15px;
            outline: none;
        }
    }
    .error{
        color: rgb(206, 40, 40);
        margin-top: 5px;
        font-size: 13px;
        font-weight: 600;
    }
    .login_short_detail{
        margin-top: 20px;
        font-size: 14px;
        margin-bottom: 20px;
    }

    .send_otp_code_button{
        background: linear-gradient(to right, #14418C, #2270F1);
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: 0px;
        color: #fff;
        cursor: pointer;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        & svg{
            margin-left: 10px;
        }
    }
}

.view_list{
  display: block;
}

.hide_list{
  display: none;
}
@media (max-width: 575.98px) { 
    .login_section{
        .login_inner_section{
            width: 90%;
        }
    }
 }
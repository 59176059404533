
@import 'assets/styles/variables';

.verify_section{
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    position: relative;
    z-index: 1;
    .header_section{
        .otp_title{
            font-size: 18px;
            margin-top: 40px;
            font-weight: 600;
            & span{
                color: #1c4fe3 !important;
                font-weight: 700;
            }
        }
    }
    .opt_title{
        margin-top: 30px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .secure_code_input{
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
        padding-left: 10px;
        outline: none;
        font-weight: 600;
        font-size: 14px;
    }
    .confirm_otp_button{
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: 0px;
        background: linear-gradient(to right, #14418C, #2270F1);
        color: #ffffff;
        font-weight: 700;
        cursor: pointer;
    }
}

.resent_otp{
    border-left: 8px solid #2270F1;
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    .resent_number{
        & span{
            font-weight: 700;
            cursor: pointer;
        }
    }
}

@media (max-width: 575.98px) { 
    .login_section{
        .login_inner_section{
            width: 90%;
        }
    }
 }
